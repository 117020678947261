<template>
  <v-card :loading="Object.keys(data).length == 0">
    <v-container v-if="Object.keys(data).length > 0" fluid>
      <div class="d-flex align-center flex-wrap mb-4" style="gap: 16px">
        <template  v-if="!data.envio_gesico" >
          <v-alert class="ma-0" type="warning" text
            >Esta factura no está en Gesico</v-alert
          >
          
          <v-btn @click.stop="enviarGesico" class="ml-auto" large color="secondary" >
            Enviar a Gesico
          </v-btn>
        </template>

        <template v-else-if="data.envio_gesico">
          <v-alert class="ma-0" type="success" text
            >Este cliente está en Gesico</v-alert
          >
          <v-alert class="ma-0" type="info" text>
            Enviado el {{ parseDate(data.fechaEnvioGesico) }} por {{ data.usuarioEnvioGesico }}
            </v-alert
          >
          <!-- <v-alert class="ma-0" type="info" text>
            Ruta en la carpeta compartida {{ data.rutaArchivoGesico }}
            </v-alert
          > -->

          <v-btn @click.stop="enviarGesico" class="ml-auto" large outlined color="secondary" >
            Quitar de Gesico
          </v-btn>

        </template>

      </div>

    </v-container>
  </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";
import { parseDate } from "@/utils/index.js";
import { parseImpagados } from "../services/parseImpagados.js";

export default {
  data() {
    return {
      rules: { req },
      creditoSolicitado: null,
      data: {},
      sending: false,
    };
  },
  components: {
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
  },
  props: {
    IdCarteraCobro: String | Number,
  },
  methods: {
    parseDate,
    async enviarGesico() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OUR_API_URL}/gesico/envioAGesico.php`,
        data: {
          IdCarteraCobro: this.IdCarteraCobro,
          token: this.$store.getters.getJwtEmpresa,
        },
      });
      this.getDatos();
    },
    getDatos() {
      axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
        method: "GET",
        params: {
          token: this.$store.getters.getJwtEmpresa,
          IdCarteraCobro: this.IdCarteraCobro,
        },
      }).then(({ data }) => {
        this.data = parseImpagados([data])[0];
      });
    },
  },
  mounted() {
    this.getDatos();
  },
};
</script>
